import React from "react";
import App from "next/app";
import * as Sentry from "@sentry/node";

Sentry.init({
  dsn:
    "https://de8a86658b364d92bad386610b5303ae@o138034.ingest.sentry.io/5202339",
  enabled: process.env.NODE_ENV === "production",
  release: process.env.npm_package_version,
});

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    return <Component {...modifiedPageProps} />;
  }
}

export default MyApp;
